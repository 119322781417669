











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'More of the compound can be recovered with multiple extractions with smaller amounts of solvent, since some organic molecules are also soluble in water.',
          value: 'moreCompound',
        },
        {text: 'It is less wasteful to use just one amount of solvent.', value: 'lessWaste'},
        {text: 'To remove all water from the organic phase.', value: 'removeWater'},
        {
          text: 'To avoid diluting the solutions too much due to too large a volume.',
          value: 'avoidDiluting',
        },
      ],
      optionsFr: [
        {
          text: 'Pour éviter que les solutions soient trop diluées en ajoutant trop de solvent.',
          value: 'avoidDilutingFr',
        },
        {
          text: "Une plus grande quantité du composé organique sera recouverte par plusieurs extractions avec des petites quantités de solvent, car certains composés organiques sont aussi solubles dans l'eau.",
          value: 'moreCompoundFr',
        },
        {text: "Pour retirer toute l'eau de la phase organique.", value: 'removeWaterFr'},
        {
          text: "C'est moins de gaspillage si on utilise seulement un volume de solvent.",
          value: 'lessWasteFr',
        },
      ],
    };
  },
};
